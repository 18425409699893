import React, { useState } from "react"
import { graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLaptop } from "@fortawesome/free-solid-svg-icons/faLaptop"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments"
import { faMobile } from "@fortawesome/pro-light-svg-icons/faMobile"
import { faBuilding } from "@fortawesome/pro-light-svg-icons/faBuilding"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { telephoneStrip } from "../utils/utils"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"
import { Button } from "../components/Buttons"

const icons = {
  laptop: faLaptop,
  phone: faPhone,
  chat: faComments
}

const ContactPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { heading, blurb, cards, closer } = data.allUniquePagesJson.nodes[0]
  const location = data.location.nodes[0]

  const [activeCard, setActiveCard] = useState("")

  return (
    <Layout className="contact-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Section>
        <Container>
          <Text as="h1" text={heading} />
          <Text as="p" text={blurb} />
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="contact-page__cards">
            {cards.map((card, i) => (
              <div
                key={i}
                className="contact-page__card"
                onMouseEnter={() => setActiveCard(i)}
                onMouseLeave={() => setActiveCard("")}>
                <FontAwesomeIcon icon={icons[card.icon]} />
                <Text as="h2" text={card.heading} />
                <Text as="p" text={card.blurb} />
                <Button
                  appearance={
                    activeCard === i ? "white large" : card.button.appearance
                  }
                  destination={card.button.destination}
                  href={card.button.href}
                  buttonText={card.button.buttonText}
                />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <Text className="has-text-centered" as="h3" text={closer} />
        </Container>
        <Container maxWidth={800}>
          <div className="contact-page__office">
            <div className="contact-page__office-address">
              <div>
                <FontAwesomeIcon
                  className="contact-page__office-icon"
                  icon={faBuilding}
                />{" "}
                <p>Address</p>
              </div>
              <div>
                <p>
                  {`${location.streetNumber} ${location.streetName}`} <br />{" "}
                  {`${location.cityName}, ${location.stateAbbr} ${location.cityZip}`}{" "}
                </p>
              </div>
            </div>
            <div className="contact-page__office-phone">
              <div>
                <FontAwesomeIcon
                  className="contact-page__office-icon"
                  icon={faMobile}
                />{" "}
                <p>Phone</p>
              </div>
              <div>
                <a
                  href={`tel:${telephoneStrip(location.phoneNumber, 1)}`}
                  title="Give us a call">
                  {location.phoneNumber}
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Container>
        <div className="hl--blue" />
      </Container>
    </Layout>
  )
}

export const contactQuery = graphql`
  query contactquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        cards {
          icon
          heading
          blurb
          button {
            destination
            appearance
            buttonText
            href
          }
        }
        closer
      }
    }
    location: allLocationsJson {
      nodes {
        cityName
        cityZip
        stateAbbr
        streetName
        streetNumber
        phoneNumber
      }
    }
  }
`

export default ContactPage
